.testimonial-stats {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  font-size: 13px;
  color: rgb(106, 115, 125);
}

.testimonial-left-stat {
  -webkit-box-flex: 1;
  flex-grow: 1;
  display: flex;
}

.language-color {
  width: 10px;
  height: 10px;
  background-color: blue;
  margin-right: 0.25rem;
  border-radius: 100%;
}

.testimonial-left-stat span {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-right: 0.75rem;
}


.testimonial-svg {
  margin-right: 0.5rem;
  min-width: 16px;
}

.testimonial-name {
  white-space: nowrap;
  line-height: 1.1;
  text-overflow: ellipsis;
  color: rgb(241, 111, 37);
  font-family: "Google Sans Regular";
  margin-bottom: 0.75rem;
  font-size: 25px;
  font-weight: 700;
  letter-spacing: -0.5px;
  overflow: hidden;
  margin: 0px;
}

.testimonial-star-svg {
  margin-right: 0.3rem;
}

.testimonial-description {
  word-wrap: break-word;
  text-align: left;
  display: -webkit-box;
  font-family: "Google Sans Regular", "Roboto", sans-serif;
  font-size: 1.5rem;
  line-height: 1.9; /* Increased for better vertical rhythm */
  padding: 40px; /* More padding for a balanced look */
  position: relative;
  color: #2a2a2a; /* Slightly darker text for contrast */
  padding-left: 9.5rem;
  padding-right: 9.5rem;
  font-style: italic;


  /* Stylistic quotation marks */
  &:before, 
  &:after {
      content: '“';
      font-size: 40px; /* Increased size for prominence */
      position: absolute;
      color: #888; /* Muted color for the quotation marks */
      padding-left: 6.5rem;
      padding-right: 7.5rem;
  }

  &:before {
      top: 20px; /* Adjusted for alignment */
      left: 20px;
  }

  &:after {
      content: '”';
      right: 20px;
      bottom: 20px;
  }
}



.testimonial-details {
  display: flex;
  flex-direction: row;
}

.flexDiv {
  display: flex;
  margin: 0;
  flex-direction: row;
  justify-content: space-between;
}
.Leftitem {
  align-content: flex-start;
}
.Rightitem {
  align-items: flex-end;
}

.testimonial-creation-date {
  margin: 0;
  font-family: "Google Sans Regular";
  font-weight: 400;
  margin-left: 0px;
  margin-right: auto;
}

@media (max-width: 768px) {
  .testimonial-name {
    font-size: 16px;
  }

  .testimonial-description {
    font-size: 18px;
  }

  .testimonial-details {
    flex-direction: column;
  }

  .testimonial-creation-date {
    font-size: 14px;
  }
}

.testimonial-card {
  display: flex !important;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 2rem;
  /* height: 500px; */
  border-radius: 50px; /* Rounded edges */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  
}

.testimonial-position {
  font-size: 18px;           /* Slightly smaller font size */
  font-weight: 600;          /* Semi-bold weight for emphasis */
  color: #777;               /* A muted color to not overshadow the testimonial */
  letter-spacing: 0.5px;    /* Slight letter spacing for elegance */
  margin-top: 15px;         /* Spacing from the testimonial content */
  text-transform: uppercase; /* Convert text to uppercase for style */
  border-top: 1px solid #e0e0e0;  /* A subtle line above the position */
  padding-top: 10px;        /* Spacing between line and position text */
}

.testimonial-wrapper {
  margin-top: 50px;
  margin-left: 400px;
  margin-right: 400px;
}


@media (max-width: 768px) {
  .testimonial-card {
    padding: 2rem;
    /* height: 1000px; */
  }

  .testimonial-description {
    padding-left: 1.5rem;
    padding-right: 1.5rem;

      /* Stylistic quotation marks */
      &:before, 
      &:after {
          content: '“';
          font-size: 40px; /* Increased size for prominence */
          position: absolute;
          color: #888; /* Muted color for the quotation marks */
          padding-left: 0rem;
          padding-right: 0rem;
      }

  }
  
  .testimonial-position {
    /* margin-top: 400px; */
  }

  .testimonial-wrapper {
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media (min-width: 768px) { 
  .testimonial-wrapper {
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media (min-width: 1560px) { 
  .testimonial-wrapper {
    margin-left: 200px;
    margin-right: 200px;
  }
}