.main-component.dark::before {
    content: "";
    position: fixed; /* Fixed position for the background */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1; /* Put it behind the content */

    background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url('../assests/images/herobg.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
