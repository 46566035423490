.testimonials-main {
  width: 100%;
  text-align: center;
}

.basic-testimonials {
  margin-left: 5%;
  margin-right: 5%;
}

.testimonials-heading-div {
  display: flex;
}

.testimonials-heading-div > * {
  flex: 1;
}

.testimonials-heading-img-div {
  align-items: center;
  justify-content: center;
}

.testimonials-heading-text-div {
  text-align: center;
}

.testimonials-heading-img-div > * {
  max-width: 100%;
  height: auto;
}

.testimonials-heading-text {
  font-size: 56px;
  font-weight: 400;
  font-family: "Google Sans Medium";
  line-height: 1.1;
  text-align: center;
  margin-top: 80px;
}

.testimonials-heading-sub-text {
  font-size: 30px;
  font-family: "Google Sans Regular";
  text-align: center;
  margin-bottom: 10px;
}

.testimonials-header-detail-text {
  font-size: 20px;
  line-height: 30px;
  margin-top: 0px;
  margin-left: 20px;
  margin-right: 20px;
  font-family: "Google Sans Medium";
}

/* .repo-cards-div-main {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(900px, 2fr));
  gap: 1rem 1rem;
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 60px;
} */

.repo-description {
  margin: 50px 0;
  font-size: 1.2rem; 
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  line-height: 1.6; 
  text-align: justify; 
  letter-spacing: 0.5px; 
  color: var(--card-text);
}

@media (max-width: 1380px) {
  .testimonials-heading-text {
    font-size: 50px;
    margin-top: 80px;
  }
  .testimonials-heading-sub-text {
    font-size: 25px;
  }
}

@media (max-width: 768px) {
  .testimonials-heading-text {
    font-size: 30px;
    margin-top: 20px;
  }

  .testimonials-heading-sub-text {
    font-size: 20px;
  }

  .testimonials-heading-div {
    flex-direction: column;
  }

  .testimonials-header-detail-text {
    font-size: 16px;
    margin-right: 0px;
    margin-left: 0px;
    line-height: normal;
    text-align: center;
  }

  /* .repo-cards-div-main {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 30px;
  } */
}

/* .custom-carousel-size {
  width: 85%;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
} */

.custom-carousel-size, .carousel, .carousel-item {
  width: 85%;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
  height: auto;    /* fit content height */
  display: inline-block; /* if you want the carousel to be only as wide as its content */
}

